.orange {
    color: rgb(72, 239, 255);
  }

.contact-div{
    /* background: linear-gradient(
      -45deg,
      #48efff,
      #0b0bc4,
      #48efff,
      #89f0f9,
      #2c7af7,
      #89f0f9,
      #89f0f9
    );
    background-size: 400% 400%;
    animation: gradient 9s ease infinite;
    height: 100vh; */
    background-color: rgb(14, 161, 206);
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .loader {
    margin-top: 50px;
    /* position: relative; */
  }
  
  .loader span {
    position: absolute;
    color: #fff;
    transform: translate(-50%, -50%);
    font-size: 3.5vh;
    letter-spacing: 5px;
  }
  
  .loader span:nth-child(1) {
    color: #fff;
    -webkit-text-stroke: 0.3px rgb(0, 57, 244);
  }
  
  .loader span:nth-child(2) {
    color: rgb(0, 4, 255);
    -webkit-text-stroke: 1px rgb(17, 0, 255);
    animation: uiverse723 3s ease-in-out infinite;
  }
  
  @keyframes uiverse723 {
    0%, 100% {
      clip-path: polygon(0% 45%, 15% 44%, 32% 50%, 
       54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
    }
  
    50% {
      clip-path: polygon(0% 60%, 16% 65%, 34% 66%, 
       51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
    }
  }

  .contact , .loader{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact{
    flex-grow: 1;
    margin-bottom: 100px;
  }

  .contact-page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .form-container {
    max-width: 700px;
    margin: 30px;
    background-color: rgb(0, 0, 112);
    padding: 30px;
    border-left: 5px solid rgb(72, 239, 255);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
  }
  
  .heading {
    display: block;
    color: rgb(72, 239, 255);
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
  
  .form-container .form .input {
    color: rgb(0, 0, 112);
    width: 100%;
    background-color: rgb(214, 214, 224);
    border: none;
    outline: none;
    padding: 10px;
    margin-bottom: 20px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    border-left: 1px solid transparent;
  }
  
  .form-container .form .input:focus {
    border-left: 5px solid rgb(72, 239, 255);
  }
  
  .form-container .form .textarea {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background-color: rgb(214, 214, 224);
    color: rgb(0, 0, 112);
    font-weight: bold;
    resize: none;
    max-height: 150px;
    margin-bottom: 20px;
    border-left: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .textarea:focus {
    border-left: 5px solid rgb(72, 239, 255);
  }
  
  .form-container .form .button-container {
    display: flex;
    gap: 10px;
  }
  
  .form-container .form .button-container .send-button {
    flex-basis: 70%;
    background: rgb(72, 239, 255);
    padding: 2.2vh;
    color: rgb(0, 0, 112);
    text-align: center;
    font-weight: bold;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .button-container .send-button:hover {
    background: transparent;
    border: 1px solid rgb(72, 239, 255);
    color: rgb(72, 239, 255);
  }
  
  .form-container .form .button-container .reset-button-container {
    filter: drop-shadow(1px 1px 0px rgb(72, 239, 255));
    flex-basis: 30%;
  }
  
  .form-container .form .button-container .reset-button-container .reset-button {
    position: relative;
    text-align: center;
    padding: 10px;
    color: rgb(72, 239, 255);
    font-weight: bold;
    background: rgb(0, 0, 112);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .button-container .reset-button-container .reset-button:hover {
    background: rgb(72, 239, 255);
    color: rgb(0, 0, 112);
  }

  .map{
    justify-content: flex-end;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .heading-contact {
    margin: 2vh;
    font-size: 8vh;
    padding: 0;
    color: rgb(72, 239, 255);
    text-shadow: 0 0.1em 20px rgb(0, 0, 112), 0.05em -0.03em 0 rgb(0, 0, 112),
      0.05em 0.005em 0 rgb(0, 0, 112), 0em 0.08em 0 rgb(0, 0, 112),
      0.05em 0.08em 0 rgb(0, 0, 112), 0px -0.03em 0 rgb(0, 0, 112),
      -0.03em -0.03em 0 rgb(0, 0, 112), -0.03em 0.08em 0 rgb(0, 0, 112), -0.03em 0 0 rgb(0, 0, 112);
    span {
      transform: scale(0.9);
      display: inline-block;
    }
    span:first-child {
      animation: bop 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite
        alternate;
    }
    span:last-child {
      animation: bopB 1s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
        infinite alternate;
    }
  }
  
  @keyframes bop {
    0% {
      transform: scale(0.9);
    }
    50%,
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes bopB {
    0% {
      transform: scale(0.9);
    }
    80%,
    100% {
      transform: scale(1) rotateZ(-3deg);
    }
  }
  .error{
    color:#fff;
  }