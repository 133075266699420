

.dialog-card {
    max-width: 320px;
    border-width: 1px;
    border-color: rgba(219, 234, 254, 1);
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 1);
    padding: 1rem;
  }
  
  .dialog-header {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .dialog-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: rgba(96, 165, 250, 1);
    padding: 0.5rem;
    color: rgba(255, 255, 255, 1);
  }
  
  .dialog-icon svg {
    height: 1rem;
    width: 1rem;
  }
  
  .dialog-alert {
    font-weight: 600;
    color: rgba(107, 114, 128, 1);
  }
  
  .dialog-message {
    margin-top: 1rem;
    color: rgba(107, 114, 128, 1);
  }
  
  .dialog-actions {
    margin-top: 1.5rem;
  }
  
  .dialog-actions a {
    text-decoration: none;
  }
  
  .dialog-mark-as-read, .dialog-read {
    display: inline-block;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.75rem 1.25rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
  
  .dialog-read {
    background-color: rgba(59, 130, 246, 1);
    color: rgba(255, 255, 255, 1);
  }
  
  .dialog-mark-as-read {
    margin-top: 0.5rem;
    background-color: rgba(249, 250, 251, 1);
    color: rgba(107, 114, 128, 1);
    transition: all .15s ease;
  }
  
  .dialog-mark-as-read:hover {
    background-color: rgb(230, 231, 233);
  }
  .dialog-box{
    position: fixed;
  top: 20vh;
  left: 0;
  width: 100%;
  height: auto;
  /* background: rgba(0, 0, 0, 0.5); */
   display: flex;
  justify-content: center; 
  align-items: center; 
  z-index: 1000;
  } 

  