
.nav-item{
    margin-left:40px;
    font-family:"Barlow";
    font-weight: 450;
    font-size: 20px;
    cursor: pointer;
}

ul.dropdown-menu.show{
    background-color: rgb(0, 0, 112) ;
}


.nav-link , .dropdown-item{
    /* color: #B7C9E2; */
    color:  rgb(72, 239, 255);
}
.nav-link:active{
    color: rgb(72, 239, 255);
}
.nav-link:hover{
    color: rgb(72, 239, 255);
}
.nav-link::after{
    color: rgb(72, 239, 255);
}

.navbar-nav{
    --bs-nav-link-color: rgb(72, 239, 255); 
    --bs-nav-link-hover-color: rgb(72, 239, 255);
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color:rgb(72, 239, 255);
}
.navbar{
    z-index: 1000;
}